.App {
  max-width: 1000px;
  margin: 0 auto;
}

.post-container {
  border: solid 1px #888;
  box-shadow: #888 2px 2px 2px;
  margin: 20px;
  padding: 10px;
}

.post-title {
  margin: 0 2px;
  padding: 0;
  font-size: 26px;
  font-weight: bold;
  color: #444;
  border-bottom: solid 2px #ddd;
}

.post-title::before {
  content: '#';
  margin-right: 10px;
  opacity: 0.5;
}

.post-title.with-input {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  flex-wrap: nowrap;
}

.post-title input {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 26px;
  font-weight: bold;
  color: #444;
  border: none;
  background: none;
  outline: none;
}

.post-username {
  color: #777;
  font-size: 14px;
  margin: 5px 0;
}

.post-username .with-input {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  flex-wrap: nowrap;
}

.post-username input {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 14px;
  color: #888;
  border: none;
  background: none;
  outline: none;
}

.make-post .header {
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 10px 0;
}

.make-post .header span {
  text-align: center;
}

.make-post .header button {
  font-size: 20px;
  padding: 5px;
  text-align: center;
  width: 120px;
  border: none;
  background-color: #f48120;
  cursor: pointer;
  color: #fff;
}

.make-post .header button:hover {
  background-color: #db6f16;
}

.make-post .header button.submitting {
  background-color: #aaa;
  cursor: progress;
}
.make-post .header button.submitting:hover {
  background-color: #aaa;
}

.make-post .header button.error {
  background-color: rgb(189, 29, 29);
}

.make-post .content-input {
  font-size: 18px;
  background: none;
  border: none;
  width: 100%;
  min-height: 100px;
  height: 200px;
  max-height: 100vh;
  resize: vertical;
  padding: 10px;
  box-sizing: border-box;
}

.placeholder {
  opacity: 0.7;
}

.posts-count {
  text-align: center;
}
